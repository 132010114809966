import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="mt-8 px-4 md:w-11/12 mx-auto dark:text-white">
      <h2 className="text-2xl md:text-3xl font-bold">
        Sparedech Privacy Policy
      </h2>
      <div>
        {/* <div className="my-8">
          <h5 className="font-semibold mb-4">
            Lorem ipsum dolor sit amet consectetur.{" "}
          </h5>
          <p>
            Senectus nullam tellus tortor quam fusce quis pellentesque tortor
            risus. Lacus cras sed quis vitae. Justo semper et pellentesque
            tempus aliquet malesuada aenean vulputate. Ornare leo praesent ut
            nec nibh dui magna. Tincidunt ornare eget mattis lectus. Et turpis
            egestas libero facilisi massa. Blandit vulputate arcu ac pulvinar
            cursus eros cursus enim mauris. Tempor semper scelerisque sed enim
            egestas nisl.
          </p>
        </div> */}
        <div className="my-8">
          <h5 className="font-semibold mb-4">Introduction</h5>
          <p>
            Sparedech Limited ("We," "Us," or "Our") is an online marketplace
            that connects buyers and sellers of spare parts. Our services also
            extend to our logistics service, which enables the shipment and
            delivery of packages from sellers to buyers. This Privacy Notice
            ("Notice") provides information on how Sparedech collects and
            processes your personal data when you visit our website or mobile
            application. We provide this Notice because you have a right to know
            what information we collect, why we collect it, how it is protected
            and used, and under what circumstances it may be disclosed. This
            Notice does not apply to any products, services, websites, or
            content offered by third parties.
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">The data we process</h5>
          <p className="mb-2">
            Personal data is any information about an individual that can be
            used to identify that person directly or indirectly. While you try
            to sign up on our website and mobile applications, we may obtain the
            following personal information from you:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li>
              Contact information: full name, email address, phone number and
              postal address
            </li>
            <li>
              Payment information: credit card information and billing address
            </li>
            <li>
              Company name, company, means of identification of the sellers
            </li>
            <li>The required information of the riders</li>
            <li>Billing information</li>
            <li>In certain cases your marketing preference</li>
          </ul>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">
            Lawful bases for processing data
          </h5>
          <p className="mb-6">
            We are required to process your data on at least one of these lawful
            bases, as specified under the relevant data protection laws:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li className="mb-2">
              <strong>Legitimate interest:</strong> Processing your data is
              necessary for our legitimate interests or the legitimate interests
              of a third party unless our legitimate interests are outweighed by
              your interest, in which case we may not rely on this lawful basis.
            </li>
            <li className="mb-2">
              <strong>Consent:</strong> You have given consent for us to process
              your data for a specific purpose. A separate consent form will be
              provided to you to obtain your consent.
            </li>
            <li className="mb-2">
              <strong>Legal obligation:</strong> If the processing of your data
              is necessary where there is a statutory obligation on us.
            </li>
            <li className="mb-2">
              <strong>Performance of a contract:</strong> This would also apply
              where we need to take steps prior to entering into a contract with
              you. For example, where you have purchased a product from us and
              we need to use your contact details and payment information in
              order to process your order and send the product to you.
            </li>
          </ul>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">
            Purpose of Processing your data and the lawful bases
          </h5>
          <table className="table-auto w-full border border-collapse border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 border-r text-[12px] md:text-[14px]">
                  Purpose of Processing
                </th>
                <th className="px-4 py-2 text-[12px] md:text-[14px]">
                  Lawful Bases
                </th>
              </tr>
            </thead>
            <tbody className="text-[12px] md:text-[14px]">
              <tr>
                <td className="border px-4 py-2">
                  To send marketing or promotional messages to you.
                </td>
                <td className="border px-4 py-2">Consent</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">
                  To perform and fulfill a contract with you for the provision
                  of our services or to take steps prior to entering into a
                  contract with you.
                </td>
                <td className="border px-4 py-2">Performance of contract</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">
                  To improve the accuracy of our records so that we can better
                  understand your needs and preferences.
                </td>
                <td className="border px-4 py-2">Legitimate interest</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">
                  To inform you whenever there are changes to our terms of
                  service.
                </td>
                <td className="border px-4 py-2">Legitimate interest</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">
                  To take statistical data and analytics for our internal use.
                </td>
                <td className="border px-4 py-2">Legitimate interest</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">
                  To meet valid requirements, including complying with court
                  orders, valid discovery requests, valid subpoenas, and other
                  appropriate legal mechanisms.
                </td>
                <td className="border px-4 py-2">Legal obligation</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">
                  To interact with regulatory authorities or other public
                  authorities concerning you.
                </td>
                <td className="border px-4 py-2">Legal obligation</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">Your rights as a data subject</h5>
          <p className="mb-4">
            The law vests you with certain rights as a data subject. They
            include the right to:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li className="mb-4">
              Access personal data we hold about you by requesting a copy of the
              personal data we hold about you;
            </li>
            <li className="mb-4">
              Rectify such information where you believe it to be inaccurate;
            </li>
            <li>
              Restrict the processing of your data in certain circumstances;
            </li>
            <li>
              Object to the processing of your data where we intend to process
              such data for marketing purposes;
            </li>
            <li>
              Where feasible, receive all personal data you have provided to
              us—in a structured, commonly used, and machine-readable format—and
              transmit the information to another data controller;
            </li>
            <li>
              Request the erasure of your data (also known as the right to be
              forgotten);
            </li>
            <li>Withdraw your consent to the processing of your data;</li>
            <li>
              Not be subjected to a decision based solely on automated
              processing or profiling; and
            </li>
            <li>
              Lodge a complaint with a relevant authority where you have reason
              to believe that we have violated the term(s) of this Notice. (You
              may complain or seek redress from us within 30 days from when you
              first detect the alleged violation.)
            </li>
          </ul>
          <p className="my-2">
            You may seek to exercise any of the above rights at any time by
            sending us an email at{" "}
            <a
              href="mailto:admin@sparedech.com"
              className="text-blue-500 underline"
            >
              admin@sparedech.com
            </a>
            .
          </p>
          <p className="mb-2">
            For the purpose of this notice, the supervisory authority is the
            Nigeria Data Protection Bureau (NDPC) and the complaint can be sent
            via email at info@ndpc.gov.ng. If you wish to withdraw your consent,
            please send us an email at{" "}
            <a
              href="mailto:admin@sparedech.com"
              className="text-blue-500 underline"
            >
              admin@sparedech.com
            </a>
            .
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">
            Who do we share your data with?
          </h5>
          <p className="mb-4">
            We collaborate with select third-party service providers and
            affiliates to provide our service:
          
          </p>
          <table className="table-auto w-full border border-collapse border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-r text-[12px] md:text-[14px]">
                    Third Parties
                  </th>
                  <th className="px-4 py-2 text-[12px] md:text-[14px]">
                    Purpose of data sharing
                  </th>
                </tr>
              </thead>
              <tbody className="text-[12px] md:text-[14px]">
                <tr>
                  <td className="border px-4 py-2">Service providers</td>
                  <td className="border px-4 py-2">
                    We share data with our service providers to deliver certain
                    services essential for the operation of the product. These
                    services include billing and delivery.
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">
                    Legal and Regulatory Authorities
                  </td>
                  <td className="border px-4 py-2">
                    We disclose your personal information if we believe it is
                    reasonably necessary to comply with a law, regulation,
                    order, subpoena, audit, or to protect any person's safety or
                    to address fraud, security, or technical issues.
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">Retention of your data</h5>
          <p>
            The personal data we process will be stored for as long as necessary
            to fulfil the purposes described in this Notice. After processing
            has been fulfilled, we will also retain personal data per the
            relevant provisions of applicable laws to resolve disputes, prevent
            fraud and abuse, and/or enforce our legal agreements and policies.
            In addition, we delete your data for targeted marketing purposes
            once you unsubscribe from our marketing communications.
          </p>
          <p>
            Please note that your data may be retained for a longer period,
            notwithstanding your request to remove it, where there is a legal
            requirement to do so.
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">
            How is your data stored and secured?
          </h5>
          <p>
            We prioritise your privacy and use advanced measures to protect your
            data. We are dedicated to safeguarding your information against
            loss, misuse, and unauthorised access. If a significant breach
            occurs, we will promptly inform you and act swiftly to address it.
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4">International transfer of data</h5>
          <p>
            We may transfer personal data outside our country of operation. If
            we do so, we ensure any cross-border data transfers adhere to all
            necessary data protection regulations. This means that before
            transferring personal data, we either confirm that the recipient
            country has robust data protection laws or, if not, employ specific
            contractual terms and other appropriate safeguards to protect the
            data. In cases where the destination country might not meet
            stringent data protection standards, we will leverage the relevant
            data transfer mechanism, seek authorisation from the regulator, or
            obtain your consent before proceeding and inform you of any risks.
            Should you wish to learn more about how we ensure data protection
            during these transfers, details will be provided upon request.
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4"> Marketing and communications</h5>
          <p>
            We only send marketing communications to you with your consent. You
            can unsubscribe from our marketing messages or object to further
            processing by contacting us via email at{" "}
            <a
              href="mailto:admin@sparedech.com"
              className="text-blue-500 underline"
            >
              admin@sparedech.com
            </a>
            and requesting to discontinue receiving marketing messages
          </p>
        </div>

        <div className="my-8">
          <h5 className="font-semibold mb-4"> Complaints</h5>
          <p>
            If you have any inquiries or complaints, contact our Data Protection
            Officer (DPO) at{" "}
            <a
              href="mailto:admin@sparedech.com"
              className="text-blue-500 underline"
            >
              admin@sparedech.com
            </a>{" "}
            Our DPO will examine your concerns and update you on the resolution
            process.
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4"> Changes to this notice</h5>
          <p>
            We occasionally update our privacy notice. We will notify you when
            we make a change.
          </p>
        </div>
        <div className="my-8">
          <h5 className="font-semibold mb-4"> Contact us</h5>
          <p>
            If you have any questions relating to this Notice or your rights
            under this Notice or are not satisfied with how we manage your
            personal data, contact our Data Protection Officer at{" "}
            <a
              href="mailto:admin@sparedech.com"
              className="text-blue-500 underline"
            >
              admin@sparedech.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
