import React from "react";
import { RouteList } from "../../container/appRoute";
import Playstore from "../../assets/icons/playStore.svg";
import Applestore from "../../assets/icons/appleStore.svg";
import ApplestoreWhite from "../../assets/icons/whiteApplyStore.svg";

const DownloadBtn = ({dark}) => {


  return (
    <div>
      <div className="flex xs:items-center xs:justify-center lg:items-left lg:justify-left md:w-full gap-x-4 mt-4 mb-12 md:mt-4 ">
        <a
          href={RouteList.LANDING}
          className="flex items-center bg-brandBlue py-3 px-4 lg:px-6 rounded-full"
        >
          <img
            className="h-4 w-auto"
            src={Playstore}
            alt=""
            width={180}
            height={37}
          />
          <span className="ml-[0.4rem] md:ml-2 text-xs lg:text-lg text-white">Get on Android</span>
        </a>
        <a
          href={RouteList.LANDING}
          className="flex items-center text-brandBlue dark:text-white border-[1px] border-brandBlue dark:border-white bg-white dark:bg-transparent  py-2 px-4 lg:px-6  rounded-full"
        >
          <img
            className="h-4 w-auto"
            src={dark ? ApplestoreWhite : Applestore}
            alt=""
            width={180}
            height={37}
          />
          <span className="ml-[0.4rem] md:ml-2 text-sm lg:text-lg">Get on iPhone</span>
        </a>
      </div>
    </div>
  );
};

export default DownloadBtn;
