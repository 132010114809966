import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  //   className: "center",
  //   centerMode: true,
  fade: true,
  waitForAnimate: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
};

const goToPrev = (sliderRef) => {
  if (sliderRef.current) {
    sliderRef.current.slickPrev();
  }
};

const goToNext = (sliderRef) => {
  if (sliderRef.current) {
    sliderRef.current.slickNext();
  }
};
const Carousel = ({ data, ChildComponent, sliderRef }) => {
  return (
    <div className="">
      <div className="slider-container md:w-full mx-auto ">
        <Slider {...settings} ref={sliderRef}>
          {data.map((item, index) => {
            return (
              <div key={index} className="">
                <ChildComponent {...item} show={true} sliderRef={sliderRef}>
                  {" "}
                </ChildComponent>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
export { goToPrev, goToNext };
