import React from "react";
import { formatDate } from "../utils/functions";
const BlogCard = ({
  id,
  subtitle,
  title,
  createdAt,
  image,
  href,
  content,
  category,
  date,
}) => {
  return (
    <div
      className={`my-8 md:my-8 lg:my-14 hover:shadow-[1px_9px_14px_-3px_rgba(0,0,0,0.75)]   appearance-none rounded-2xl`}
      style={
        {
          // boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.75)"
        }
      }
    >
      <div
        className={`flex flex-col lg:flex-row w-full  md:gap-x-20 lg:items-center `}
      >
        <div className="basis-8/12 w-full">
          <div className="" style={{ width: "100%" }}>
            <img
              className="xs:h-auto md:max-h-[400px] object-cover  w-full xs:rounded-2xl md:rounded-l-2xl"
              src={image}
              alt=""
              loading="lazy"
              // style={{ width: "100%", height: "400.38px" }}
            />
          </div>
        </div>
        <div className={` basis-8/12 px-2`}>
          <p className="mt-8 mb-4 text-xl md:text-xl lg:pr-14 text-brandBlue">
            {category ? category : "Article"}
          </p>
          <h3 className="mt-8 mb-4 font-bold text-3xl md:text-3xl text-left ">
            {title}
          </h3>
          <p className="my-2 text-xl md:text-xl lg:pr-14">
            {createdAt ? formatDate(createdAt) : date}
          </p>
          <div className=" mt-6 mb-12 md:mt-12 ">
            <a
              href={`${href}/${id}`}
              className=" bg-brandBlue text-white py-4 px-6 rounded-full "
            >
              <span className=" text-lg">Read</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
