import React from "react";
import Logo from "../../assets/icons/newLogo.svg";
import LogoWhite from "../../assets/icons/logoWhite.svg";
import { RouteList } from "../../container/appRoute";
import XApp from "../../assets/icons/xBlue.svg";
import XAppWhite from "../../assets/icons/xAppWhite.svg";
import Whatsapp from "../../assets/icons/whatsapp.svg";
import WhatsappWhite from "../../assets/icons/whatsappWhite.svg";
import Instagram from "../../assets/icons/igBlue.svg";
import InstagramWhite from "../../assets/icons/instagramWhite.svg";

import Phone from "../../assets/images/phone.svg";
import PhoneDark from "../../assets/images/phoneDarkBg.svg";
import footerBg from "../../assets/images/footerbg.svg";
import { Element } from "react-scroll";
import { footerLinks } from "../../mockData";
import DownloadBtn from "../hero/downloadBtn";

const Footer = ({ dark }) => {
  const socials = [
    { name: "whatsapp", icon: dark ? WhatsappWhite : Whatsapp, href: "https://wa.me/2349034506953" },
    { name: "x-app", icon: dark ? XAppWhite : XApp, href: "https://www.x.com/sparedech" },
    { name: "instagram", icon: dark ? InstagramWhite : Instagram, href: "https://www.instagram.com/sparedech" },
  ];

  return (
    <div className="">
      <div className="">
        <div
          className="pt-14 px-4 md:pl-14 md:pr-8"
          // style={{
          //   background: `url(${footerBg})`,
          //   backgroundSize: "cover",
          //   backgroundRepeat: "no-repeat",
          //   backgroundPosition: "center",
          // }}
        >
          <div className="flex flex-col-reverse dark:text-white  lg:grid lg:grid-cols-2 items-center md:items-left lg:items-left">
            <div
              className="px-4 mt-12 md:mt-0 md:pl-8 flex flex-col items-center text-center md:text-left lg:text-left lg:items-left"
              style={{
                background: `url(${!dark && footerBg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <h3 className="font-bold text-xl md:text-[2.3rem] lg:text-[3rem] leading-[1.3]">
                Buy, Sell and Deliver Spare Parts,
                <span className="md:block inline">
                  All on <span className="text-[#2868DB]">SPAREDECH</span>
                </span>
              </h3>
              <p className="w-full my-4 text-md md:text-[1.6rem] md:text-left lg:text-left xs:text-center">
                Download the app NOW to get started
              </p>
              <Element
                name="footer"
                className="flex xs:items-center xs:justify-center lg:items-left lg:justify-left md:w-full gap-x-8 mt-8 mb-12 md:mt-8"
              >
                 <DownloadBtn dark={dark} />
               
              </Element>
            </div>
            <div className="" style={{}}>
              <img
                // className="h-auto w-auto "
                src={dark ? PhoneDark : Phone}
                alt=""
                style={{ width: "100%", height: "400.38px" }}
              />
            </div>
          </div>
        </div>
        <div
          className=" text-[#2868DB] py-4 px-4 text-center"
          style={
            {
              // background:
              //   "linear-gradient(to bottom, rgba(0, 30, 110, 1) 50%, rgba(34, 97, 218, 1) 100%)",
            }
          }
        >
          <div className="px-4 lg:px-16">
            <div className=" my-16">
              <div className="flex flex-col md:flex-row  justify-between items-center md:items-start">
                <a href={RouteList.LANDING}>
                  <img
                    className="h-8 w-auto"
                    src={dark ? LogoWhite : Logo}
                    alt=""
                    width={180}
                    height={37}
                  />
                </a>

                <div className="grid grid-cols-3 gap-12 my-12 md:my-0 dark:text-white">
                  {footerLinks.map((item) => {
                    return (
                      <div key={item.title}>
                        <p className="font-semibold"> {item.title} </p>
                        <div className="flex flex-col  ">
                          {item.paths.map((path) => {
                            return (
                              <a
                                key={path.id}
                                href={path.route}
                                className={`text-xs font-light leading-4 mt-4`}
                              >
                                {path.text}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="flex gap-x-6 my-6 md:my-0">
                  {socials.map((item) => {
                    return (
                      <a
                        key={item.name}
                        href={item.href}
                        className="rounded-full  px-[0.5rem] py-[0.5rem] shadow appearance-none border-1 dark:border-2 border-[#CBCBCB] dark:border-white shadow-outline bg-opacity-40   md:px-2  "
                      >
                        <img
                          className="h-6 w-auto"
                          src={item.icon}
                           rel="noopener noreferrer"
                          target="_blank"
                          alt=""
                          width={180}
                          height={37}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            <p className="text-[#2868DB] mt-12 dark:text-white">
              Copyright &copy; 2024 Sparedech All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
