import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../../assets/icons/newLogo.svg";
import LogoDark from "../../assets/icons/logoWhite.svg";
import Light from "../../assets/icons/lightMode.svg";
import Dark from "../../assets/icons/darkMode.svg";
import { RouteList } from "../../container/appRoute";
import { Link as ScrollLink } from "react-scroll";

const NavBar = ({ dark, onToggleDarkMode }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  const navigation = [
    // { name: "Home", href: RouteList.LANDING },
    { name: "About us", href: "about" },
    { name: "Contact us", href: "contact" },
    { name: "Blog", href: RouteList.BLOG },
    { name: "FAQs", href: RouteList.FAQ },
  ];
  return (
    <div className=" md:w-11/12  max-w-screen-xl mx-auto">
      <header className=" inset-x-0 top-0 z-50 bg-transparent">
        <nav
          className="flex items-center justify-between p-6 lg:px-2"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <div className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <a href={RouteList.LANDING}>
                <img
                  className="h-6 md:h-8 w-auto"
                  src={dark ? LogoDark : Logo}
                  alt=""
                  width={180}
                  height={37}
                />
              </a>
            </div>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-brandBlue dark:text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" style={{ strokeWidth: 3 }}/>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => {
              if (item.href === "about" || item.href === "contact") {
                return (
                  <ScrollLink
                    key={item.name}
                    to={item.href}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className={`text-sm font-normal leading-6  cursor-pointer dark:text-white `}
                    // ${
                    //   router.asPath === item.href
                    //     ? "text-brandBlue"
                    //     : "text-black"
                    //   }
                  >
                    {item.name}
                  </ScrollLink>
                );
              }
              return (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-normal leading-6  cursor-pointer dark:text-white"
                >
                  {item.name}
                </a>
              );
            })}
          </div>

          <ScrollLink
            to={"footer"}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="hidden lg:flex lg:flex-1 lg:justify-end"
          >
            <p
              // key={item.name}
              // href={item.href}
              className={`text-sm mx-6 text-white dark:bg-white dark:text-black1 font-semibold leading-4 cursor-pointer bg-brandBlue py-3 px-6 rounded-full`}
            >
              Download Sparedech
            </p>
          </ScrollLink>
          <img
            className="h-8 w-auto hidden md:block"
            src={!dark ? Light : Dark}
            alt=""
            width={180}
            height={37}
            onClick={onToggleDarkMode}
          />
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white dark:bg-[#000A1C] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <div className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <a
                  href={RouteList.LANDING}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <img
                    className="h-6 w-auto"
                    src={dark ? LogoDark : Logo}
                    alt=""
                    width={180}
                    height={37}
                  />
                </a>
              </div>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6 text-brandBlue dark:text-white font-semi-bold" aria-hidden="true" style={{ strokeWidth: 3 }} />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 ">
                <div className="space-y-2 py-8  text-center dark:text-white">
                  {navigation.map((item) => {
                    if (item.href === "about" || item.href === "contact") {
                      return (
                        <ScrollLink
                          key={item.name}
                          to={item.href}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          className="block rounded-lg px-3 py-2 text-base font-normal leading-7 sm:text-grey lg:text-grey hover:bg-gray-50 cursor-pointer"
                          onClick={closeMobileMenu}
                          // ${
                          //   router.asPath === item.href
                          //     ? "text-brandBlue"
                          //     : "text-black"
                          //   }
                        >
                          {item.name}
                        </ScrollLink>
                      );
                    }
                    return (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-lg px-3 py-2 text-base font-normal leading-7 sm:text-grey lg:text-grey hover:bg-gray-50 cursor-pointer"
                        onClick={closeMobileMenu}
                      >
                        {item.name}
                      </a>
                    );
                  })}
                </div>
                <div className="flex items-center justify-center mb-8">
                  <ScrollLink
                    to={"footer"}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="flex lg:flex-1 lg:justify-end"
                  >
                    <p
                      // key={item.name}
                      // href={item.href}
                      className={`text-sm mx-6 text-white dark:bg-white dark:text-black1 font-semibold leading-4 cursor-pointer bg-brandBlue py-3 px-6 rounded-full`}
                    >
                      Download Sparedech
                    </p>
                  </ScrollLink>
                </div>
                <div className="flex items-center justify-center dark:text-white">
                  <span>Turn on dark mode</span>
                  <img
                    className="h-8 w-auto ml-2"
                    src={!dark ? Light : Dark}
                    alt=""
                    width={180}
                    height={37}
                    onClick={onToggleDarkMode}
                  />
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
};

export default NavBar;
