export function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const suffix = getDaySuffix(day);
  
    const formattedDate = date.toLocaleString(undefined, {
    //   weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   second: "2-digit",
    //   hour12: true,
    });
  
    return formattedDate.replace(/\b(\d{1,2})\b/g, "$1" + suffix);
  }
  

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
        return "th";
    }
    switch (day % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}