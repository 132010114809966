import React from "react";
import { Element } from "react-scroll";
import { corevalues } from "../../mockData";
import ValueCard from "./ValueCard";

const About = () => {
  return (
    <Element name="about">
      <div className="px-4 md:px-14 pt-14 md:py-14">
        <div className="md:mb-20">
          <h2 className="font-bold text-[18px] lg:text-4xl text-center mb-6 dark:text-white ">
            The <span className="text-[#2868DB]">Problem</span> We{" "}
            <span className="text-[#2868DB]">Solve</span>
          </h2>
          <p className="mt-4 mb-8 text-[12px] md:text-[1.5rem] font-light dark:text-white">
            Finding authentic spare parts from trusted vendors in an ever-busy
            trade market can be a major challenge. We are bridging the gap by
            providing a seamless, efficient, and secure way for buyers and
            sellers to do business.
          </p>
        </div>
        <div className="mb-20">
          <h2 className="font-bold text-xl text-center lg:text-4xl mb-12 dark:text-white">
            Our Core <span className="text-[#2868DB]">Values</span>
          </h2>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-6 md:gap-x-12 gap-y-10">
            {corevalues.map((item, idx) => {
              return (
                <ValueCard
                  key={item.id}
                  {...item}
                  bg={`${
                    idx === 0 && "bg-[#2868DB] text-white dark:bg-[#001B4E]"
                  } ${
                    idx === 1 &&
                    "bg-white dark:bg-[#000A1C] dark:text-white dark:border-2 dark:border-white"
                  } ${
                    idx === 2 &&
                    "bg-white text-black1 md:text-white md:bg-[#2868DB] dark:text-white dark:bg-[#000A1C] dark:md:bg-[#001B4E] dark:border-2 dark:md:border-0 dark:border-white"
                  } ${
                    idx === 3 &&
                    "bg-[#2868DB] text-white sm:text-black1 sm:bg-white dark:bg-[#001B4E] dark:md:bg-[#000A1C] dark:text-white dark:md:border-2 dark:border-white"
                  } `}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Element>
  );
};

export default About;
