import React from "react";

const ValueCard = ({id, image,title,content, bg, textCol }) => {
  return (
    <div className=" ">
      <div className={`h-full h-72 ${bg} flex flex-col items-center text-center py-6 px-2 md:px-4 rounded-bl-[50px] rounded-tr-[50px] shadow-custom appearance-none border-1 border-[#CBCBCB] shadow-outline`}>
        <div className="rounded-full w-16 h-16  bg-white shadow-custom flex items-center justify-center">
          <img className="w-[40px] h-[40px]" src={image} alt="" loading="lazy" />
        </div>
        <div className={`${textCol}`}>
          <h2 className="font-bold text-[13px] mt-6 mb-6">{title}</h2>
          <p className="text-[12px]">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default ValueCard;
