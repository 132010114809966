import React from "react";
import Hero from "../components/hero/Hero";
import Community from "../components/community/Community";

const Home = ({ dark }) => {
  return (
    <div>
      <Hero dark={dark} />
      <Community />
    </div>
  );
};

export default Home;
