import React from "react";
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';


const Animate  = ({ children, xVariant = 0, yVariant = 0 }) => {

    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.5, 
    });
    
  return (
      <motion.div
      ref={ref}
      initial={inView ? 'visible' : 'hidden'}
      animate={inView ? 'visible' : 'hidden' }
    //   initial="visible"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, x: xVariant, y :yVariant },
        visible: { opacity: 1, x: 0 },
      }}
    >
      {children}
    </motion.div>
  );
};

export default Animate;
