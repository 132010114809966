import React, { useState } from "react";
import SearchIcon from "../assets/icons/searchIcon.svg";
import Accordion from "../components/faq/Accordion";
import FaqCategories from "../components/faq/FaqCategories";
import { accordionItems } from "../mockData";

const Faq = ({ dark }) => {
  const [activeCategory, setActiveCategory] = useState("General");
  const [searchQuery, setSearchQuery] = useState("");

  const categories = [...new Set(accordionItems.map((item) => item.category))];

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredItems = accordionItems.filter(
    (item) =>
      item.category === activeCategory &&
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="">
      <div
        className="bg-brandBlue dark:bg-[#082250] flex flex-col text-center items-center justify-center"
        style={{
          background: dark
            ? "linear-gradient(to bottom, #082250 100%, #000A1C 100%)"
            : "linear-gradient(to bottom, #C5D9FD 100%, #E4EEFF 100%)",
          height: "400px",
          clipPath: "polygon(0% 0%, 100% 0%, 100% 85%, 50% 95%, 0% 85%)",
        }}
      >
        <h2 className="text-2xl md:text-5xl font-bold  dark:text-white">
          Frequently Answered Questions
        </h2>
        <p className="text-sm md:text-xl my-6 font-light  dark:text-white">
          Sparedech has an encompassing network of ....
        </p>
        <div className="relative w-11/12 md:w-3/6">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchInputChange}
            placeholder="Search for a question"
            className="shadow appearance-none border-1 border-[#CBCBCB] rounded-full w-full py-3 pl-12 md:pl-16 "
            // style={{ backgroundbackgroundImg: `url(${SearchIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: '10px center', paddingLeft: '50px' }}
          />
          <img
            src={SearchIcon}
            alt="Search Icon"
            className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 "
          />
        </div>
      </div>
      <div className="px-4 sm:px-8 lg:py-14 lg:px-14">
        <div className="md:flex sm:gap-x-[3rem] md:gap-x-[8rem] mt-12">
          <FaqCategories
            categories={categories}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
          <Accordion items={filteredItems} activeCategory={activeCategory} />
        </div>
      </div>
    </div>
  );
};

export default Faq;
