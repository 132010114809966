import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as FormMeta from "./contactValidator";
import axios from "axios";

const ContactForm = () => {
  const { InputFieldNames } = FormMeta;

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    // process.env.BASE_URL
  };

  return (
    <div className="">
      <div className="mb-6">
        <h3 className="mt-6 md:mt-8  md:text-[28px] lg:text-3xl text-2xl font-bold text-foundation-black dark:text-white">
        Get in touch with us
        </h3>
        <p className="mt-4 pr-6 dark:text-white">
        Have a question or concern? We’d love to hear from you! Whether you're a buyer, seller or rider, our team is always here to lend a helping hand.
        </p>
      </div>
      <Formik
        initialValues={FormMeta.ContactInitialValue}
        validationSchema={FormMeta.ContactValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="bg-white dark:bg-transparent rounded-xl  pt-6 pb-8 mb-4">
            <div className="mb-6">
              <label
                className="block  dark:text-white text-sm font-bold mb-2"
                htmlFor={InputFieldNames.NAME}
              >
                Name
              </label>
              <Field
                className="text-sm md:text-base  dark:bg-transparent dark:text-white shadow appearance-none border border-[#CBCBCB] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name={InputFieldNames.NAME}
                placeholder="Name"
              />
              <ErrorMessage
                className="text-error text-xs mt-2"
                name={InputFieldNames.NAME}
                component="div"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 dark:text-white text-sm font-bold mb-2"
                htmlFor={InputFieldNames.EMAIL}
              >
                Email
              </label>
              <Field
                className="text-sm md:text-base dark:bg-transparent dark:text-white shadow appearance-none border border-[#CBCBCB] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email"
                name={InputFieldNames.EMAIL}
                placeholder="yourmail@gmail.com"
              />
              <ErrorMessage
                className="text-error text-xs mt-2"
                name={InputFieldNames.EMAIL}
                component="div"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 dark:text-white text-sm font-bold mb-2"
                htmlFor={InputFieldNames.MESSAGE}
              >
                Message
              </label>
              <Field
                as="textarea"
                className="text-sm md:text-base dark:bg-transparent dark:text-white shadow appearance-none border border-[#CBCBCB] rounded w-full py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline"
                name={InputFieldNames.MESSAGE}
                placeholder="Message"
                rows={4}
              />
              <ErrorMessage
                className="text-error text-xs mt-2"
                name={InputFieldNames.MESSAGE}
                component="div"
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-brandBlue rounded-full text-white font-bold py-3 w-9/12 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isSubmitting}
              >
                Send message
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
