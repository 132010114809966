import React from "react";
import ContactForm from "../form/contact/ContactForm";
import InfoCard from "./InfoCard";
import { Element } from "react-scroll";

const ContactUs = () => {
  return (
    <Element name="contact">
      <div 
      className="md:px-4 sm:px-8 lg:pl-16"
      >
        <div className="flex flex-col lg:flex-row gap-x-20 items-center justify-between ">
          <div className="basis-3/6 px-4">
            <ContactForm />
          </div>

          <div className="flex w-full items-right  basis-4/6">
            <div className="relative w-full justify-end flex items-center justify-right ">
              <div className="relative bg-[#153875] sm:w-[400px] rounded-2xl left-[40px] sm:left-[60px] z-10 ">
                <InfoCard />
              </div>
              <div className="relative bg-brandBlue dark:bg-[#012B7A] w-36 h-[400px] sm:h-[500px] z-0 rounded-l-xl"     
             
               ></div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default ContactUs;
