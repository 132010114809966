import React from "react";
import { useParams } from "react-router-dom";
import { data } from "../mockData";
import BlogBanner from "../components/blogCard/BlogBanner";

const BlogArticle = () => {
  const { id } = useParams();
  const singleData = data.find((item) => item.id === id);
  const { content } = singleData;

  const paragraphs = content.header.split("\n\n");

  return (
    <div className="w-11/12 md:w-11/12 mx-auto">
      <BlogBanner {...singleData} show={false} />
      {/* <p key={index}>{paragraph}</p> */}
      <div className="mt-8 md:w-10/12 mx-auto dark:text-white">
        {paragraphs.map((paragraph, index) => (
          <p key={paragraph.id || index * 2} className=" py-4 leading-loose">
            {paragraph}
          </p>
        ))}
        <div>
          <h2 className="font-bold mb-6">{content.item.itemHeader}</h2>
          {content.item.items.map((item, idx) => {
            return (
              <div className="" key={idx * 2}>
                <p className="mb-6 text-[16px]">
                  <span className="font-bold">{item.title}</span>
                  <span className="ml-2">{item.detail}</span>
                </p>
              </div>
            );
          })}
          <p>{content.item?.summary}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
