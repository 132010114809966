import React from "react";
import "./Faq.css";

const FaqCategories = ({ categories, activeCategory, setActiveCategory }) => {
  return (
    <div className=" md:basis-[30%]">
      <div className="border-2 border-borderGrey pr-2 md:rounded-xl">

      <div className="options-container">
        <div
          className="flex md:flex-col md:items-left md:justify-left  md:px-4  options-container pb-4 dark:text-white"
          style={{ maxHeight: "400px", overflowY: "scroll" }}
        >
          {categories.map((category, index) => (
            <button
              key={category}
              className={`font-light md:px-4 md:py-4 mx-4 md:mx-0 ${
                category === activeCategory
                  ? "border-b-4 border-black dark:border-white"
                  : "border-b-2 border-grey2"
              }`}
              onClick={() => setActiveCategory(category)}
            
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
};

export default FaqCategories;
