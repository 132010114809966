import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="mt-8 px-4 md:w-11/12 mx-auto">
      <h2 className="text-2xl md:text-3xl font-bold dark:text-white">Sparedech Terms and Conditions</h2>

      <div>
        <div className="my-8 dark:text-white">
          <h5 className="font-semibold mb-4">
            Lorem ipsum dolor sit amet consectetur.{" "}
          </h5>
          <p className="">
            Senectus nullam tellus tortor quam fusce quis pellentesque tortor
            risus. Lacus cras sed quis vitae. Justo semper et pellentesque
            tempus aliquet malesuada aenean vulputate. Ornare leo praesent ut
            nec nibh dui magna. Tincidunt ornare eget mattis lectus. Et turpis
            egestas libero facilisi massa. Blandit vulputate arcu ac pulvinar
            cursus eros cursus enim mauris. Tempor semper scelerisque sed enim
            egestas nisl.
          </p>
        </div>
        <div className="my-8 dark:text-white">
          <h5 className="font-semibold mb-4">
            Donec dictum sed dictumst etiam praesent lacus maecenas eu.
          </h5>
          <p>
            Tortor aliquet netus nec aliquet aliquet velit gravida egestas non.
            Senectus potenti varius sit tristique consequat eu. Ut penatibus sed
            tincidunt eget cras viverra porta. Pharetra amet a id tristique ut
            libero elementum. Nam iaculis placerat id enim mi tincidunt dui
            duis. Congue ornare velit vitae magna scelerisque mauris est. Quis
            varius consequat lacus amet odio sit nullam duis. Nisl cursus diam
            ullamcorper praesent elementum integer.{" "}
          </p>
        </div>
        <div className="my-8 dark:text-white">
          <h5 className="font-semibold mb-4">
            Morbi purus mattis at euismod varius sed.
          </h5>
          <p>
            Vel nisl mauris integer dolor at a. Hac sed ut dignissim lorem
            semper elementum sapien. Enim elit amet aliquet quam facilisis velit
            fames integer nisi. Viverra pretium magna laoreet gravida proin sed
            risus varius. Pharetra purus placerat viverra sed at aliquet vel at
            eu. Diam blandit in diam tellus. Nisl odio est congue molestie
            adipiscing tempus ultricies id. Mi accumsan id donec non senectus
            ultricies ac. Id venenatis enim sit enim lorem mi ac sed. Morbi et
            cursus justo mauris nunc. Porttitor maecenas facilisis luctus
            egestas bibendum semper. Turpis orci senectus eget purus. Diam nam
            proin orci non amet sit diam. Elementum .
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
