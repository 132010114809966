import React, { useState } from "react";
import NoSearchResultImg from "../../assets/images/noResult.svg";

const Accordion = ({ items, activeCategory }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const filteredItems = activeCategory
    ? items.filter((item) => item.category === activeCategory)
    : items;
  return (
    <div className="accordion basis-[50%] mt-8 md:mt-0 dark:text-white">
      {filteredItems.length === 0 ? (
        <div>
          <p className="text-center mb-6">
            No result matching your search was found
          </p>
          <div className="flex items-center justify-center content-center">
            <img
              className=""
              src={NoSearchResultImg}
              alt="No search result found"
              loading="lazy"
            />
          </div>
        </div>
      ) : (
        filteredItems.map((item, index) => (
          <div
            key={index}
            className="accordion-item border-b border-grey2 pt-4 pl-6"
          >
            <button
              className={`accordion-title ${
                index === activeIndex ? "active" : ""
              } font-bold mb-4`}
              onClick={() => toggleAccordion(index)}
            >
              {item.title}
            </button>
            <div
              className={`accordion-content pb-4 ${
                index === activeIndex ? "block" : "hidden"
              }`}
            >
              {item.content}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Accordion;
