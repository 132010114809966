import React, { useState, useEffect, useRef } from "react";
import BlogCard from "../components/blogCard/BlogCard";
import SearchIcon from "../assets/icons/searchIcon.svg";
import Carousel from "../components/blogCard/Carousel";
import BlogBanner from "../components/blogCard/BlogBanner";
import { useNavigate } from "react-router-dom";
import { data as mockData } from "../mockData";
import NoSearchResultImg from "../assets/images/noResult.svg";

const Blog = () => {
  const ItemsPerPage = 3;
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef(null);
  const [data, setData] = useState(mockData);

  // useEffect(() => {
  //   axios
  //     .get("https://sparedech-dashboard-api.vercel.app/getall")
  //     .then((response) => {
  //       // setData(response.data.post || []);
  //       console.log(response.data.post);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  useEffect(() => {
    const filtered = data.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchQuery, data]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // pagination
  const totalPages = Math.ceil(filteredData.length / ItemsPerPage);

  const startIndex = (currentPage - 1) * ItemsPerPage;
  const endIndex = Math.min(startIndex + ItemsPerPage, filteredData.length);

  const currentItems = filteredData.slice(startIndex, endIndex);
  // const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="px-4 sm:px-8 lg:py-8 lg:px-14">
      <Carousel
        data={mockData}
        ChildComponent={BlogBanner}
        sliderRef={sliderRef}
      />
      <div className=" flex items-center justify-center mt-12">
        <div className="relative w-11/12 md:w-4/6">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchInputChange}
            placeholder="Search for an article"
            className="shadow appearance-none border-1 border-[#CBCBCB] rounded-full w-full py-3 pl-12 "
            // style={{ backgroundbackgroundImg: `url(${SearchIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: '10px center', paddingLeft: '50px' }}
          />
          <img
            src={SearchIcon}
            alt="Search Icon"
            className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 "
            loading="lazy"
          />
        </div>
      </div>
      <h3 className="font-bold text-center mt-8 mb-8 text-4xl dark:text-white ">
        Recent blog posts
      </h3>
      <div className="dark:text-white">
        {currentItems.length > 0 ? (
          currentItems.map((item, idx) => {
            // console.log("bog id ",item.blog_id)
            // console.log("current items",currentItems)
            return (
              <div
                key={item.blog_id || idx * 2}
                onClick={() => navigate(`${item.href}/${item.id}`)}
              >
                <BlogCard {...item} />
              </div>
            );
          })
        ) : (
          <div>
            <p className="text-center">
              No result matching your search was found
            </p>
            <div className="flex items-center justify-center content-center">
              <img
                className=""
                src={NoSearchResultImg}
                alt="No search result found"
                loading="lazy"
              />
            </div>
          </div>
        )}
      </div>

      {filteredData.length >= 4 && (
        <div className="flex items-center justify-center space-x-4 mt-4 ">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="px-4 py-2 bg-gray-200 rounded-md"
          >
            Previous
          </button>

          {Array.from(
            { length: totalPages > 3 ? 3 : totalPages },
            (_, i) => i + 1
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`md:px-2 py-2 rounded-md ${
                currentPage === page ? "text-brandBlue" : "text-gray-700"
              }`}
            >
              {page}
            </button>
          ))}
          <span className={``}>
            {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="px-4 py-2 bg-gray-200 rounded-md"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Blog;
