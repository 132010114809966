import React,{useEffect} from "react";
import { Route, Routes as Router } from "react-router-dom";
import { RouteList } from "./appRoute";
import Home from "../pages/Home";
import Blog from "../pages/Blog";
import BlogArticle from "../pages/BlogArticle";
import Layout from "../components/layout/Layout";
import Faq from "../pages/Faq";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndCondition from "../pages/TermsAndCondition";

const AppRoutes = () => {
  const [dark, setDark] = React.useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode) {
      setDark(JSON.parse(savedDarkMode));
      if (JSON.parse(savedDarkMode)) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    }
  }, []);

  const darkModeHandler = () => {
    setDark((prevDark) => {
      const newDark = !prevDark;
      localStorage.setItem("darkMode", JSON.stringify(newDark));
      if (newDark) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
      return newDark;
    });
  };
  return (
    <Layout dark={dark} onToggleDarkMode={darkModeHandler} >
      <Router>
        <Route path={RouteList.LANDING} element={<Home dark={dark} />} />
        <Route path={RouteList.BLOG} element={<Blog />} />
        <Route
          path={`${RouteList.BLOG_ARTICLE}/:id`}
          element={<BlogArticle />}
        />
        <Route path={RouteList.FAQ} element={<Faq dark={dark} />} />
        <Route path={RouteList.PRIVACY} element={<PrivacyPolicy/>} />
        <Route path={RouteList.TERMS_AND_CONDITIONS} element={<TermsAndCondition/>} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Router>
    </Layout>
  );
};

export default AppRoutes;
