import React from "react";
import NavBar from "../header/NavBar";
import Footer from "../footer/Footer";
import About from "../about/About";
import ContactUs from "../contactUs/ContactUs";
import { useLocation } from "react-router-dom";
import { RouteList } from "../../container/appRoute";
import { Link as ScrollLink, Element } from "react-scroll";
import Top from "../../assets/icons/topIcon.svg";
import TopBlack from "../../assets/icons/topIconBlack.svg";

const Layout = ({ children, dark, onToggleDarkMode }) => {
  const location = useLocation();
  const isFaqRoute = location.pathname === RouteList.FAQ;
  const isPrivacy = location.pathname === RouteList.PRIVACY;
  const TAndC = location.pathname === RouteList.TERMS_AND_CONDITIONS;

  return (
    <div className="dark:bg-black1 scroll-wrapper ">
      <div
      className=""
      >
        <Element name="header">
          <NavBar dark={dark} onToggleDarkMode={onToggleDarkMode} />
        </Element>
        <main>{children}</main>

        {isFaqRoute && (
          <h3 className="text-3xl md:text-4xl font-bold text-center text-[#153875] mt-14">
            Didn’t find what you’re looking for?
          </h3>
        )}

        {(isPrivacy || TAndC) && (
          <h3 className="text-3xl md:text-4xl font-bold text-center text-[#153875] mt-14 dark:text-white">
            Still need clarifications on our Terms?
          </h3>
        )}

        {isFaqRoute || isPrivacy || TAndC ? (
          <>
            <ContactUs />
            <About />
          </>
        ) : (
          <>
            <About />
            <ContactUs />
          </>
        )}
        <Footer dark={dark} />
      </div>
      <ScrollLink
        to={"header"}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        className={`text-sm font-normal leading-6  cursor-pointer dark:text-white z-10`}
      >
        <div className="flex items-center justify-center fixed right-4 md:right-8 bottom-1/2 transform translate-y-1/2 cursor-pointer w-[42px] h-[42px] bg-[#011233] dark:bg-white text-white dark:text-[#011233] rounded-md shadow-lg">
          <img src={dark ? TopBlack : Top} alt="scroll to top icon" />
        </div>
      </ScrollLink>
    </div>
  );
};

export default Layout;
