import React from "react";
import Animate from "../Animate/Animate";

const CommunityCard = ({
  title,
  content,
  href,
  bg,
  reverse,
  spacing,
  textCol,
  image
}) => {
  return (
    <div className={`${bg} rounded-bl-[40px] rounded-tr-[40px] pt-6`}>
  <div
  className={`flex lg:flex-row w-full md:px-8 items-stretch justify-between`}
>
  <div className={`pl-4 pt-6 basis-full lg:basis-8/12 ${spacing} ${textCol} sm:mt-0`}>
    <h3 className="font-bold text-lg md:text-[1.7rem] text-left mb-4">
      {title}
    </h3>
    <p className="my-2 text-sm sm:text-2xl lg:text-lg leading-5">
      {content}
    </p>
  </div>

  <div className="basis-full lg:basis-8/12 w-full h-[250px] sm:h-[350px] md:h-[350px] lg:h-[350px] relative">
    <div className="gap-x-8 pr-4 md:px-0 absolute inset-x-0 bottom-0">
    <Animate xVariant={50} yVariant={0}>
      <img
        className="w-full md:w-auto h-full"
        src={image}
        alt="About sparedech" 
        loading="lazy"
        style={{
          width: "100%",
          height: "auto",
          maxHeight: "400.38px",
          objectFit: "cover",
        }}
      />
       </Animate>
    </div>
  </div>
</div>

    </div>
  );
};

export default CommunityCard;
