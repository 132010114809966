import React from "react";
import Tools from "../../assets/images/toolsImage.svg";
import ToolsMobile from "../../assets/images/toolsFull.svg";
import DownloadBtn from "./downloadBtn";

const Hero = ({ dark }) => {
  return (
    <div style={{}}>
      <div className="text-white pt-8 ">
        <div className="flex flex-col lg:flex-row content-center items-center">
          <div className="px-4 md:pl-12 flex flex-col items-left text-left basis-8/12  mt-4 md:mt-12 lg:mt-16 text-center md:text-left">
            <h3 className="font-black text-brandBlue dark:text-white text-2xl sm:text-5xl md:text-[44px] lg:text-[50px] sm:leading-[1.2]">
              The Automobile Spare Parts Marketplace Built For You
            </h3>
            <p className="my-8 text-sm  md:text-2xl  text-[#011233] dark:text-white lg:pr-6">
              Sparedech allows you to connect with trusted spare part sellers,
              enjoy secure transactions and experience fast, reliable delivery.
            </p>
            <div className="hidden md:block flex xs:items-center xs:justify-center lg:items-left lg:justify-left">
              <DownloadBtn dark={dark} />
            </div>
          </div>
          <div
            className="basis-8/12 my-8 md:mt-0 flex items-right justify-end"
            style={{}}
          >
            <img
              className="hidden md:block lg:h-[400.38px] md:w-auto "
              src={Tools}
              alt=""
              loading="lazy"
              // style={{ width: "100%", objectFit: "fill" }}
            />
            <img
              className="block md:hidden w-80 lg:h-[400.38px] w-auto "
              src={ToolsMobile}
              alt=""
              loading="lazy"
              // style={{ width: "100%", objectFit: "fill" }}
            />
          </div>
          <div className=" block md:hidden flex xs:items-center xs:justify-center lg:items-left lg:justify-left">
            <DownloadBtn dark={dark} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
