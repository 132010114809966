import { RouteList } from "./container/appRoute";
import ArticleImg from "./assets/images/articleImage.svg";
import Buyer from "./assets/images/buyerImg.svg";
import Rider from "./assets/images/riderImg.svg";
import Vendor from "./assets/images/vendorImg.svg";
import Escrow from "./assets/images/escrowImg.svg";
import Customer from "./assets/icons/customerFocused.svg";
import Reliability from "./assets/icons/reliability.svg";
import Transparency from "./assets/icons/transparency.svg";
import Security from "./assets/icons/security.svg";
import BlogImg1 from "./assets/images/blog-img-1.png"
import BlogImg2 from "./assets/images/blog-img-2.png"

export const communityData = [
  {
    id: "001",
    title: "Buyers",
    content: "Find spare parts you need and get it delivered to your doorstep.",
    href: RouteList.FAQ,
    image: Buyer,
  },
  {
    id: "101",
    title: "Vendors",
    content:
      "Upload your products with ease and reach a wider range of buyers.",
    href: RouteList.FAQ,
    image: Vendor,
  },
  {
    id: "201",
    title: "Riders",
    content: "Get paid per trip while you enjoy flexible work hours.",
    href: RouteList.FAQ,
    image: Rider,
  },
  {
    id: "202",
    title: "Escrow",
    content: "Buy and sell securely with fraud protection.",
    href: RouteList.FAQ,
    image: Escrow,
  },
];
export const corevalues = [
  {
    id: "101",
    title: "Customer-focused",
    content:
      "We built Sparedech with the goal of ensuring you have the most seamless experience while helping you solve your spare parts needs.",
    image: Customer,
  },
  {
    id: "001",
    title: "Reliability",
    content:
      "From transactions to logistics, Sparedech is like that ONE best friend you can always count on.",
    image: Reliability,
  },
  {
    id: "201",
    title: "Transparency & Trust",
    content:
      "Clear product descriptions, vendor ratings, real-time order tracking to keep you informed every step of the way.",
    image: Transparency,
  },
  {
    id: "202",
    title: "Security",
    content:
      "We prioritize fair and safe transactions with our escrow payment system, protecting both buyers and sellers from fraud.",
    image: Security,
  },
];

export const data = [
  {
    id: "1",
    subtitle: "Article",
    title: "Car Care 101: Essentials Spare Parts That Save the Day",
    date: "1st June 2024",
    image: BlogImg1,
    href: RouteList.BLOG_ARTICLE,
    content: {
      header:
        "Let's be honest, most of us have undoubtedly experienced a frustrating car breakdown at some point in our lives. Whether it was an unexpected flat tyre on a road trip, or a dead battery on our way to work. But it doesn't always have to be that way. Keeping a few spares in the boot of your car can help fix your car and get you back on the road in no time.",
      item: {
        itemHeader:
          "Here are the top 5 must-have spare parts every car owner should have on hand:",
        items: [
          {
            title: "Tyre-changing essentials:",
            detail:
              "Flat tyres are a roadside classic. Always be prepared with a properly inflated spare tyre, a working jack and a lug wrench.",
          },
          {
            title: "Engine coolant:",
            detail:
              "Engine overheating can be a serious issue. While engine coolants are the best alternative for your radiator, a small amount of water can serve as a temporary option in emergencies. (Safety note: Avoid opening hot radiators. If you find that your radiator is overheated, wait for it to cool before adding your coolant.)",
          },
          {
            title: "Basic tool kit:",
            detail:
              "Your small toolbox should contain essentials like pliers, screwdrivers, tapes and fuses. This will come in handy for quick fixes.",
          },
          {
            title: "Jumper cables:",
            detail:
              "We can all agree that one of the most common automotive malfunctions is a dead battery. Jumper cables will help you borrow power from another car to revive your dead battery.",
          },
          {
            title: "Shovel:",
            detail:
              "Although this may take up space in your boot, a shovel can be helpful in case you get stuck in mud, sand or situations where you need to dig.",
          },
        ],
        summary: "",
      },
    },
  },
  {
    id: "2",
    subtitle: "Article",
    title: "Signs Your Car Needs Servicing (Before the Dashboard Tells You)",
    date: "8th July 2024",
    image: BlogImg2,
    href: RouteList.BLOG_ARTICLE,
    content: {
      header:
        "Think of your car as your body. Just like our bodies need regular checkups to stay healthy, cars need regular maintenance to last longer. Every car manufacturer recommends specific service intervals to keep your vehicle running smoothly for years to come. The problem? We all get busy. Sometimes, car maintenance gets pushed off, just like that doctor's appointment you keep rescheduling. But here's the thing: your car has ways of telling you it's due for a checkup, just like your body sends signals when something is wrong.",

      item: {
        itemHeader:
          "Here are some ways your car might tell you it's time for a checkup:",
        items: [
          {
            title: "Trouble with Brakes:",
            detail:
              "Your brakes should feel smooth when stopping the car. If you feel shaking, hear squeaking, or need to press extra hard to slow down, there might be a problem.",
          },
          {
            title: "Weird Noises:",
            detail:
              "Any strange sounds from your car deserve attention. Hissing might mean an overheated engine, squealing could be a worn-out belt, and grinding could be parts rubbing together. It's best to have a mechanic check it out.",
          },
          {
            title: "Leaking Fluids:",
            detail:
              "A little water condensation is normal, but coloured liquids dripping from your car indicate trouble. It could be oil, brake fluid, or something that needs fixing immediately. Don't try to diagnose it yourself - take it to a professional.",
          },
          {
            title: "Lack of Power:",
            detail:
              "Does your car jerk to a stop sometimes? Or does it struggle to accelerate like it used to? This could mean it needs a service to fix spark plugs, fuel injection, or other parts.",
          },
          {
            title: "Smoke or Fumes:",
            detail:
              "Smoke coming from your car is never a good sign. It could be caused by worn-out parts, overheating, or radiator problems. If you see smoke, pull over safely and call for help. Don't risk driving it further.",
          },
          {
            title: "Slow Acceleration:",
            detail:
              "If your car used to pick up speed quickly but now feels sluggish, it might need servicing. This can happen when going up a slope or driving along a highway.",
          },
          {
            title: "Rough Gear Shifts:",
            detail:
              "Shifting gears should be smooth. If you feel resistance or grinding when changing gears, or if your automatic car isn't smooth between modes, it's time for a mechanic to check it out.",
          },
        ],
        summary:
          "Overall, don't wait for the dashboard warning light before paying attention to the signs. When your car gives you an indication, refer to your car's manual for more information. But just like you wouldn't treat yourself based on online information, it's best to take your vehicle to a mechanic for a proper diagnosis and fix.",
      },
    },
  },
];

export const accordionItems = [
  {
    category: "General",
    title: "Is Sparedech free to use?",
    content:
      "Currently, buying and selling on Sparedech is FREE. To access the app’s features, all you have to do is create an account. However, we're always looking for ways to improve and expand our services. In the near future, we may transition to a paid subscription model to offer even more features and benefits to our users.",
  },
  {
    category: "General",
    title: "Who is Sparedech for?",
    content:
      "Sparedech is for everyone in the auto industry. Mechanics, vehicle owners, auto dealers, as well as dispatch riders looking to join our logistics team.",
  },
  {
    category: "Buying Parts",
    title: "How do I find the parts I need?",
    content:
      "Finding spare parts on the app is easy. You can search based on the car model, specific part, location or vendor you’re looking for.",
  },
  {
    category: "Buying Parts",
    title: "Can I trust the sellers on Sparedech?",
    content:
      "Absolutely. Our vendors undergo a verification process that ensures they meet our standards for quality and reliability. Plus, our escrow payment system means your funds are held securely until you receive your order.",
  },
  {
    category: "Buying Parts",
    title: "What happens if a part I ordered doesn’t fit my vehicle?",
    content:
      "Returns are always an option if something isn’t quite right. If you have any issues with your order, kindly message the vendor to report the issue. You will then proceed to the “request a refund” section on the app. If the vendor has previously consented to an exchange or refund, your request will be processed immediately. In cases of disagreement, our escrow team then steps in to help resolve such issues.",
  },
  {
    category: "Buying Parts",
    title: "What condition can I expect for used parts?",
    content:
      "Vendors are responsible for accurately describing the condition of their parts. We encourage you to review the product details and photos carefully before making a purchase.",
  },
  {
    category: "Buying Parts",
    title: "Does Sparedech run 24/7?",
    content:
      "Currently, no. Items are available for order between 9am to 6pm, Mondays-Saturdays. Sundays are excluded.",
  },
  {
    category: "Buying Parts",
    title: "Can I negotiate the price of a part?",
    content:
      "Of course. Many vendors on Sparedech are open to offers. Feel free to send a message to the seller to discuss the price.",
  },
  {
    category: "Buying Parts",
    title: "How long will my favourite listings stay active?",
    content:
      "Your favourites will stay active as long as the items are still in stock and available for sale.",
  },
  {
    category: "Selling Parts",
    title: "How do I sell my spare parts on Sparedech?",
    content:
      "Simply sign up as a seller, take clear photos of your products, describe their condition and set a price. The app is designed such that it’s easy for you to manage your products and transactions.",
  },
  {
    category: "Selling Parts",
    title: "How and when do I get paid?",
    content:
      "Once a buyer confirms their order, the money is released to you securely through our system.",
  },
  {
    category: "Selling Parts",
    title: "Do I have to pay to sell on Sparedech?",
    content:
      "Currently, you don’t. Listing your products on our platform comes at ZERO cost. However, we're always looking for ways to improve and expand our services. In the near future, we may transition to a paid subscription model to offer even more features and benefits to our users.",
  },
  {
    category: "Selling Parts",
    title: "How long will my listing stay active?",
    content:
      "Your listing will remain active for as long as you want it to. You can, however, delete a listing if you no longer want it up for sale.",
  },
  {
    category: "Selling Parts",
    title: "Does the service run 24/7?",
    content:
      "Currently, no. Work hours run through Mondays to Saturdays, from 9am-6pm.",
  },
  {
    category: "Payments",
    title: "How does the escrow payment system work?",
    content:
      "Our escrow payment system acts as a secure intermediary, holding the buyer’s funds until the transaction is completed satisfactorily. Once the buyer receives their order and confirms satisfaction, the funds are released to the seller, providing peace of mind for both parties.",
  },
  {
    category: "Payments",
    title: "What payment methods do you accept?",
    content:
      "We accept a variety of secure payment options for your convenience, including credit & debit cards, USSD and bank transfers.",
  },
  {
    category: "Payments",
    title: "What happens if I cancel my order?",
    content:
      "If you cancel your order before the vendor sends out your order, you will receive a full refund through your preferred payment method.",
  },
  {
    category: "Delivery",
    title: "How much does delivery cost?",
    content:
      "Delivery costs vary depending on the size, weight and location of your order. You’ll see the exact cost of delivery before you finalize your purchase.",
  },
  {
    category: "Delivery",
    title: "How soon can I get my order?",
    content:
      "Delivery times may vary depending on factors such as the vendor's location, selected mode of transport, and your own location. In rare instances, unforeseen circumstances like bad weather, unexpected delays with our riders, or network downtime could impact delivery times. However, we strive to provide a fast and reliable delivery service to ensure you receive your parts as quickly as possible.",
  },
  {
    category: "Delivery",
    title: "Can I track my order?",
    content:
      "Sure thing. Once your order has been dispatched, you'll be able to see the location of the rider in real-time. For added security, we'll send you a unique delivery pin shortly before your order arrives. The rider will request this pin upon delivery to confirm they're handing the package to the right person. This helps ensure your order gets into the correct hands. If you're unable to provide the delivery pin for any reason, the rider won't be able to complete the delivery to ensure package security. In this case, please contact us through the app and we'll be happy to help reschedule your delivery.",
  },
  {
    category: "Delivery",
    title: "What if there’s an issue with my delivery?",
    content:
      "If you encounter any issues with your delivery, such as delays or damaged items, please reach out to our customer support team immediately. We'll do everything we can to resolve the situation quickly and fairly.",
  },
  {
    category: "Riders",
    title: "How do I become a rider on Sparedech?",
    content:
      "We’re always on the lookout for more riders to join our team. Simply download the Sparedech app and follow the sign-up process.",
  },
  {
    category: "Riders",
    title: "What kind of vehicles do I need to deliver spare parts?",
    content:
      "As long as you have a reliable mode of transportation, such as a bicycle, motorcycle, car or van, you’re eligible to be a Sparedech rider.",
  },
  {
    category: "Riders",
    title: "Can I choose a schedule that works for me?",
    content:
      "Yes, you can. You can choose times that work best for you, so you have time for other commitments.",
  },
  {
    category: "Riders",
    title: "What are the weekly working hours?",
    content:
      "Work hours run through Mondays to Saturdays, from 9am-6pm. We offer some flexibility in scheduling, so you can choose shifts that work best for you.",
  },
  {
    category: "Account & Security",
    title: "How do I create an account on Sparedech?",
    content:
      "Creating an account is easy. Simply download the app and follow the signup process.",
  },
  {
    category: "Mobile App",
    title: "Does Sparedech have a mobile app?",
    content:
      "Of course! Our mobile app is available to download on both Playstore and Appstore.",
  },
];

export const footerLinks = [
  {
    title: "Products",
    paths: [
      { id: "1", text: "Marketplace", route: "#" },
      { id: "2", text: "Delivery", route: "#" },
    ],
  },
  {
    title: "Support",
    paths: [
      { id: "3", text: "FAQs", route: "/faq" },
      { id: "4", text: "Blog", route: "/blog" },
    ],
  },
  {
    title: "Legal",
    paths: [
      { id: "5", text: "Terms", route: "/terms-and-conditions" },
      { id: "6", text: "Privacy", route: "/privacy" },
    ],
  },
];
