/* eslint-disable max-len */
import * as Yup from "yup";

export const InputFieldNames = {
  NAME: "name",
  EMAIL: "email",
  MESSAGE: "message",
  
} 




export const ContactInitialValue = {
    [InputFieldNames.NAME]: "",
    [InputFieldNames.EMAIL]: "",
    [InputFieldNames.MESSAGE]: "",
  };
  export const ContactValidationSchema = Yup.object().shape({
    [InputFieldNames.NAME]: Yup.string().required("This field is required"),
    [InputFieldNames.EMAIL]:Yup.string().email('Invalid email address').required('Required'),
    [InputFieldNames.MESSAGE]: Yup.string().required('Required'),
  });