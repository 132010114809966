import React from "react";
import { RouteList } from "../../container/appRoute";
import Next from "../../assets/icons/nextIcon.svg";

const BlogBanner = ({
  id,
  title,
  content,
  image,
  show,
  sliderRef,
  category,
}) => {
  const firstFullStopIndex = content.header.indexOf(".");
  const secondFullStopIndex = content.header.indexOf(
    ".",
    firstFullStopIndex + 1
  );
  const slicedContent = content.header.slice(0, secondFullStopIndex + 1);

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div
      className="relative bg-center bg-no-repeat bg-cover rounded-2xl"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50 rounded-2xl"></div>
      <div className={`md:w-11/12  max-w-screen-xl mx-auto relative z-10`}>
        <div className={` py-12 sm:py-24 lg:py-20`}>
          <div className="text-left mt-8 sm:mt-12 mx-4 max-w-2xl">
            <p className="text-white">{category ? category : "Article"}</p>
            <h1
              className="text-2xl font-bold text-white sm:text-5xl leading-8"
              style={{ lineHeight: 1.5 }}
            >
              {title}
            </h1>
            <p className="mt-6 text-lg leading-8 text-white">{slicedContent}</p>
          </div>
          <div className="flex items-center content-center justify-between mt-10 mx-4">
            {show === true && (
              <div className="">
                <a
                  href={`${RouteList.BLOG_ARTICLE}/${id}`}
                  className="rounded-full bg-indigo-600 px-4 py-3.5 text-sm font-semibold text-white bg-brandBlue shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Learn more
                </a>
              </div>
            )}
            {show === true && (
              <button
                className="text-white bg-grey2 bg-opacity-40 px-6 py-4 rounded-full"
                onClick={() => goToNext(sliderRef)}
              >
                <img
                  className="h-8 w-auto"
                  src={Next}
                  alt=""
                  width={180}
                  height={37}
                  loading="lazy"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBanner;
