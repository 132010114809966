import React from "react";
import CommunityCard from "./CommunityCard";
import { communityData } from "../../mockData";

const Community = () => {
  return (
    <div className="px-4 mt-14  sm:px-8 py-14 lg:pb-24 lg:px-14">
      <div className="text-center mb-12">
        <h2 className="font-bold text-[18px] dark:text-white lg:text-4xl">
          What can <span className="text-[#2868DB]">you</span> do with{" "}
          <span className="text-[#2868DB]"> Sparedech?</span>
        </h2>
        <p className="text-[12px] md:text-[1.1rem] font-light dark:text-white mt-1">
          Sparedech has a broad range of functionalities tailored just for you.
        </p>
      </div>
      <div className="grid grid-cols lg:grid-cols-2 md:gap-x-20 gap-y-20 justify-end">
        {communityData.map((item, idx) => {
          return (
            <CommunityCard
              key={item.id}
              {...item}
              bg={`${
                idx === 0 &&
                "bg-[#2868DB] dark:bg-[#000A1C] border-2 dark:border-white text-white"
              } ${
                idx === 1 &&
                "bg-[#C5D9FD] dark:bg-[#001B4E] dark:text-white dark:border-2 dark:border-white"
              } ${
                idx === 2 &&
                "bg-[#2868DB] text-white md:text-black1 md:bg-[#C5D9FD] dark:bg-[#000A1C]  dark:md:bg-[#001B4E] dark:text-white dark:border-2 dark:border-white"
              } ${
                idx === 3 &&
                "bg-[#C5D9FD] md:text-white md:bg-[#2868DB] dark:bg-[#001B4E] dark:text-white dark:md:bg-[#000A1C] dark:border-2 dark:border-white"
              } `}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Community;
