import React from "react";
import Mail from "../../assets/icons/mailIcon.svg";
import PhoneIcon from "../../assets/icons/phoneIcon.svg";
import Location from "../../assets/icons/locationIcon.svg";
const InfoCard = () => {
  return (
    <div className="bg-[#153875] rounded-2xl text-white  pb-6 px-4 mt-12 ">
      <h4 className="mb-4 text-xl font-bold">Info</h4>
      <div className="flex items-center mb-4 py-2  ">
        <img className="h-3 w-auto" src={Mail} alt="" width={180} height={37} loading="lazy" />
        <span className="ml-2 text-lg">info@sparedech.com</span>
      </div>
      <div className="flex items-center mb-2 py-2 ">
        <img
          className="h-3 w-auto"
          src={PhoneIcon}
          alt=""
          width={180}
          height={37}
          loading="lazy"
        />
        <span className="ml-2 text-lg">+234 903 450 6953</span>
      </div>
      <div className="flex  mb-2 py-2 ">
        <img
          className="h-3 w-auto mt-2"
          src={Location}
          alt=""
          width={180}
          height={37}
          loading="lazy"
        />
        {/* <span className="ml-2 text-lg">5, Alhaji Mudashiru Awe Street, Jibowu, Yaba, Lagos </span> */}
        <span className="ml-2 text-lg">
          367 Agege motor road, opposite NNPC filling station, Lagos{" "}
        </span>
      </div>
    </div>
  );
};

export default InfoCard;
